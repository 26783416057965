/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { ReactComponent as DVLASearch } from "../../../assets/icons/dvla-search.svg";
import "../styles.scss";
import { Input, Table } from "antd";
import { useSelector } from "react-redux";
import { dateFormat } from "../../../utils/constants";
import Button from "../../../components/Buttons";
import DVLAExport from "../../../assets/icons/dvla-export.svg";

const VerifyDriver = ({ getDriver, handleSearch, paginationData, setPaginationData, exportData }) => {
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(5);
  const { all_drivers, loading } = useSelector((state) => state?.drivers);

  const columns = [
    {
      title: "FULL NAME",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "DRIVER'S LICENSE",
      dataIndex: "ndl",
      key: "ndl",
    },
    {
      title: "LICENSE ISSUE DATE",
      dataIndex: "lasdriIssueDate",
      key: "lasdriIssueDate",
    },
    {
      title: "LICENSE EXIPIRY DATE",
      dataIndex: "licenseExpiryDate",
      key: "licenseExpiryDate",
    },
    {
      title: "DRIVER RATING",
      dataIndex: "rating",
      key: "rating",
    },
  ];

  const data = all_drivers &&
    all_drivers?.content?.length > 0 &&
    all_drivers?.content?.map((vd, index) => {
      return {
        key: index,
        firstName: vd?.firstName ?? "---",
        phoneNumber: vd?.phoneNumber ?? "---",
        ndl: vd?.ndl ?? "---",
        lasdriIssueDate: vd?.lasdriIssueDate ? dateFormat(vd?.lasdriIssueDate) : "---",
        licenseExpiryDate: vd?.licenseExpiryDate ? dateFormat(vd?.licenseExpiryDate) : "---",
        rating: vd?.rating ?? "---",
        id: vd?.id,
      };
    });

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"< Prev"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Next >"}
        </a>
      );
    }
    return originalElement;
  };

  const handleChange = (e) => {
    handleSearch(e?.target?.value);
  };

  const onShowSizeChange = (current, pageSize) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current - 1);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current - 1,
      size: pageSize,
    });
  };

  return (
    <div className="w-full">
      <p className="text-secondary-300 text-sm-regular font-medium">
        Search by driver’s name or mobile number
      </p>
      <div className="w-full flex gap-x-4 mt-6 justify-between">
        <div className="w-4/12">
          <Input
            onChange={handleChange}
            className={"form-field_input_0"}
            placeholder="Search drivers name, mobile number"
            prefix={<DVLASearch />}
            allowClear
          />
        </div>
        <div className="w-8/12 flex justify-end">
          <Button
            css={"py-[9px] rounded-md lg:text-[10px] text-[10px] xl:px-[14px] px-2"}
            state={"PRIMARY"}
            text={"Export Data"}
            type={"button"}
            icon={DVLAExport}
          onClick={exportData}
          />
        </div>
      </div>
      <div className="w-full mt-10" style={{ minHeight: "20rem" }}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          size={"small"}
          className={"vcd-table"}
          onRow={(driver) => {
            return {
              onClick: () => {
                getDriver(driver?.id);
              },
            };
          }}
          pagination={{
            pageSizeOptions: ["5", "10", "20", "30", "40"],
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            defaultCurrent: defaultCurrent,
            total: all_drivers?.totalElements,
            pageSize: defaultPageSize,
            showTotal: (total) => `Total ${total} items`,
            defaultPageSize: defaultPageSize,
            onChange: paginationChangeHandler,
            itemRender,
          }}
        />
      </div>
    </div>
  );
};

export default VerifyDriver;
