import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import Login from "./pages/Auth/Login";
import Landing from "./pages/Auth/Landing";
import PartnerLogin from "./pages/Auth/Login/PartnerLogin";
import Partners from "./pages/Auth/Login/Partners";
import Dashboard from "./pages/Main/Dashboard";
import Rides from "./pages/Main/Rides";
import { NewInvoice, ViewInvoices } from "./pages/Main/Invoices";
import Analytics from "./pages/Main/Analytics";
import DVLADatabase from "./pages/Main/DVLADatabase";
import Drivers from "./pages/Main/Drivers";
import DriverInfo from "./pages/Main/Drivers/DriverInfo";
import Cyclops from "./pages/Main/Cyclops";
import ChangePassword from "./pages/Main/Dashboard/ChangePassword";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import Accounts from "./pages/Main/Accounts";
import Activation from "./pages/Auth/Activation";
import Companies from "./pages/Main/Companies";
import NewCompany from "./pages/Main/Companies/NewCompany";
import DVLADriverDetails from "./pages/Main/DVLADatabase/DVLADriverDetails";
import DVLAVehicleDetails from "./pages/Main/DVLADatabase/DVLAVehicleDetails";
import AccountInfo from "./pages/Main/Accounts/AccountInfo";
import RideBatches from "./pages/Main/RideBatches";
import { getTenantFavicon } from "./utils/constants";

const DynamicFavicon = ({ faviconUrl }) => {
  useEffect(() => {
    const updateFavicon = (url) => {
      let favicon = document.querySelector("link[rel='icon']");
      if (!favicon) {
        favicon = document.createElement("link");
        favicon.setAttribute("rel", "icon");
        document.head.appendChild(favicon);
      }

      favicon.setAttribute("href", url);
    };

    if (faviconUrl) {
      updateFavicon(faviconUrl);
    }
  }, [faviconUrl]);

  return null;
};

const App = () => {
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 1000,
      easing: "ease-in-sine",
      delay: 100,
    });
    document.title = "DVLA";
  }, []);

  return (
    <div className="App">
      <DynamicFavicon faviconUrl={getTenantFavicon()} />
      <Router>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/login/partners" element={<Partners />} />
          <Route
            exact
            path="/login/partner-login/:id"
            element={<PartnerLogin />}
          />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/activation" element={<Activation />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route
            exact
            path="/dashboard/change-password"
            element={<ChangePassword />}
          />
          <Route exact path="/rides" element={<Rides />} />
          <Route exact path="/invoices/view" element={<ViewInvoices />} />
          <Route exact path="/invoices/new" element={<NewInvoice />} />
          <Route exact path="/analytics" element={<Analytics />} />
          <Route exact path="/dvla-database" element={<DVLADatabase />} />
          <Route
            exact
            path="/dvla-database/driver/:id"
            element={<DVLADriverDetails />}
          />
          <Route
            exact
            path="/dvla-database/vehicle/:id"
            element={<DVLAVehicleDetails />}
          />
          <Route exact path="/drivers" element={<Drivers />} />
          <Route exact path="/drivers/details/:id" element={<DriverInfo />} />
          <Route exact path="/cyclops" element={<Cyclops />} />
          <Route exact path="/accounts" element={<Accounts />} />
          <Route exact path="/accounts/details/:id" element={<AccountInfo />} />
          <Route exact path="/partners" element={<Companies />} />
          <Route exact path="/partners/new" element={<NewCompany />} />
          <Route exact path="/ride-batches" element={<RideBatches />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
