/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "../styles.scss";
import { useDispatch } from "react-redux";
import Manage from "./Manage";
import { debounce } from "lodash";
import { ExportViolations, FetchViolations } from "../../../redux/actions/violations.action";
import EmailPrompt from "../../../components/EmailPrompt";
import { notification } from "antd";
import { FetchBusinesses } from "../../../redux/actions/businesses.action";

const Violations = () => {
    const dispatch = useDispatch();
    const [query, setQuery] = useState({ query: "" });
    const [open, setOpen] = useState(false);
    const [paginationData, setPaginationData] = useState({
        page: 0,
        size: 10,
    });
    const [company, setCompany] = useState();

    useEffect(() => {
        dispatch(FetchViolations({ ...paginationData, ...query, businessId: company }));
    }, [dispatch, paginationData, query, company]);

    useEffect(() => {
        dispatch(FetchBusinesses({}));
    }, [dispatch]);

    const handleSearch = (value) => {
        performSearch(value);
    };

    const performSearch = debounce((value) => {
        if (value.length > 0) {
            setQuery({ query: value });
        } else {
            setQuery({ query: "" });
        }
    }, 700);

    const exportData = () => {
        setOpen(true);
    }

    const handleCallBack = (email) => {
        dispatch(ExportViolations({ ...paginationData, ...query, email, businessId: company })).then(async (res) => {
            setOpen(false);
            notification.success({
                message: "Success",
                description: "Download request received. You will receive an email when the report is ready.",
            });
        }).catch((err) => {
            notification.error({
                message: "Error",
                description: err.response.data.errorMsg
                    ? err.response.data.errorMsg
                    : "An error has ocurred, please try again later",
            });
        });
    }

    return (
        <MainLayout title={"Violations"}>
            <Manage
                exportData={exportData}
                handleSearch={handleSearch}
                paginationData={paginationData}
                setPaginationData={setPaginationData}
                getCompanyId={setCompany}
            />
            <EmailPrompt open={open} setOpen={setOpen} callBack={handleCallBack} />
        </MainLayout>
    );
};

export default Violations;
